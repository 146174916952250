import React from 'react';
import { Link } from 'gatsby';
import { useState } from 'react';
// import logo from "../images/logo.svg";
import logo from "../images/talentworxlogo.png";
import close from "../images/close.svg";
import hamburger from "../images/hamburger.svg";
import { ContactForm, useModal } from './ContactForm';

export const isBrowser = (typeof window !== undefined);

export const Header = () => {
  const { modalAction,showModal } = useModal();
  const location = window.location.href;
  let url = location.split("/")
  url = url[url.length - 2]
    
  const activePage=`/${url}/`
  const [open, setOpen] = useState(false);
 
  const headers = [
    {
      name: "About us",
      path: "/about/"
    },
    {
      name: "HRMS",
      path: "/hrms/"
    },
    {
      name: "Attendance",
      path: "/attendance/"
    },
    {
      name: "Payroll",
      path: "/payroll/"
    }
  ];
  
  return (
    <header className='h-auto sticky top-0 z-[2] bg-white'>
      
      <div className='hidden md:block'>
        {/* <div className='absolute gradient z-[-1] h-[100%] w-[100%]'></div> */}
        <div className='absolute  z-[-1] h-[100%] w-[100%]'></div>
            <div className='py-5 w-11/12 mx-auto flex items-center justify-between'>
              <Link to="/"><img src={logo} alt="logo" className='' width={100} /></Link>
              <div className='flex items-center space-x-6'>
                {
                  headers.map((header, index) => {
                    return (
                      <Link to={header.path} key={index}>
                        <p className={`text-[14px] font-[700] px-2 cursor-pointer normal ${activePage===header.path && "active"}`} >{header.name}</p>
                    </Link>)
                  })
                }
                <button className='bg-[#0361FE] w-[150px] md:w-auto pb-2 text-white py-[8px] px-[20px] outline-none rounded-[48px]' onClick={modalAction}>Book a Demo</button>
              </div>
        </div>
      </div>
      
      
      <div className={`md:hidden transition-height duration-200 ease-in-out ${open ?"h-[310px]":"h-[65px]"}`}>
        <div className='absolute  z-[-1] h-[100%] w-[100%]'></div>
        {/* <div className='absolute gradient z-[-1] h-[100%] w-[100%]'></div> */}
        <div>
          <hr className='mx-auto w-11/12'/>
          <div className='px-6 pt-4 flex items-center justify-between w-2/3'>
            <img src={!open ? hamburger : close} className="h-[20px]" alt="logo" onClick={()=>setOpen(!open)}/>
            <Link to="/"><img src={logo} alt="logo" className='h-[30px]' /></Link>

          </div>
          {
            open && <div className='space-y-3 flex flex-col items-center py-4'>
              {
                headers.map((header, index) => {
                  return (
                    <Link to={header.path} key={index}><p className={`text-[14px] font-[700] px-2 cursor-pointer normal ${activePage===header.path && "active"}`}>{header.name}</p></Link>
                  )
                })
              }
                <button className='bg-[#0361FE] w-[150px] md:w-auto pb-2 text-white py-[8px] px-[20px] rounded-[48px]' onClick={modalAction}>Book a Demo</button>

            </div>
          }
        </div>
      </div>
      {/* <ContactForm closeModal={() => setOpenModal(false)} show={openModal} /> */}
    </header>
  )
}