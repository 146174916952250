import React from 'react';
import insta from "../images/insta.svg";
import fb from "../images/fb.svg";
import tweet from "../images/tweet.svg";
import linkedInLogo from "../images/linkedInLogo.svg";
import spiral from "../images/spiral.svg";
import spectrawiseName from "../images/spectrawiseName.svg";
import { Link } from 'gatsby';
import logo from '../images/talentworx.png'
import talentlogo from "../images/Footerlogo1.png";


export const Footer = () => {
  const quickLinks = [
    { name: "HRMS", path: "/hrms/" },
    { name: "Attendance", path: "/attendance/" },
    { name: "Payroll", path: "/payroll/" },
    { name: "Verify", path: "/verify/" }
    ]
  
  const quickLinksSectionTwo = [
    { name: "Onboard", path: "/onboard/" },
    { name: "Upskill", path: "/upskill/" },
    { name: "About us", path: "/about/" },
    { name: "Privacy Policy", path: "/privacy-policy/" },

  ]
  
  const socialIcons = [
    {
      icon: fb,
      link: "https://www.facebook.com/Spectrawise-101463492799634",
      name:"facebook"
    },
    // {
    //   icon:tweet
    // },
    // {
    //   icon:linkedInLogo
    // },
    // {
    //   icon:insta
    // },
  ]
  return (
    <footer className='bg-[#0361FE] p-10 text-white '>
      <div className='flex flex-col md:flex-row lg:justify-between xl:w-10/12 mx-auto'>
        <div>
          <div className='space-y-1 mr-5'>
            {/* <img src={logo} alt="logo" width={50}/> */}
            <img src={talentlogo} alt="name" width={120}/>
          </div>
          <div className='mt-8 hidden md:block'>
            <h5 className='text-[18px]'>Follow us on</h5>
            <div className='flex space-x-8 items-center mt-2'>
              {
                socialIcons.map((icon, index) => {
                  return (
                      <a href={icon.link} title={icon.name} target="_blank"><img key={index} src={icon.icon} alt={icon.name} className="cursor-pointer"/></a>
                  )
                })
              }
            </div>
          </div>
      </div>

      <div className='flex space-x-6 mt-6 md:mt-0 lg:space-x-[100px] xl:mr-14 2xl:mr-18'>

        <div className="md:w-1/3 lg:w-3/5">
          <h3 className='font-[800] text-[18px]'>Quick links</h3>
            <div className='w-full flex flex-col lg:flex-row justisfy-between mt-4'>
              <div className='font-[400] text-[16px]  md:w-2/4'>
                {
                  quickLinks.map((link, index) => {
                    return <Link to={link.path} key={index}>
                      <p className='mt-1 lg:mt-2'>{link.name}</p>
                    </Link>
                  })
                }
              </div>
              <div className='font-[400] text-[16px] md:w-2/4'>
                {
                  quickLinksSectionTwo.map((link, index) => {
                    return <Link to={link.path} key={index}>
                      <p className='mt-1 lg:mt-2'>{link.name}</p>
                    </Link>
                  })
                }
              </div>
          </div>
        </div>
        <div className='w-3/5 md:w-11/12'>
            <div>
            <h5 className='font-[800] text-[18px]'>Address</h5>
              <p className='mt-4 font-bold mb-2'>Talntworx Technologies Private Limited.</p>
              <p >Spero Sierra, 1st Floor, Awfis,</p>
              <p> 21, Bethel Nagar St, Industrial Estate,</p>
              <p>Perungudi,Chennai, Tamil Nadu 600096.</p>
              {/* <p className='mt-2'>GSTIN: 33ABGCS1943Q1ZW</p> */}
              <p>Phone Number: +91 93456 06431</p>
          </div>
          
        </div>
        
      </div>
      </div>
       <div className='mt-6 md:hidden'>
            <h5 className='text-[18px]'>Follow us on</h5>
            <div className='flex space-x-8 items-center mt-2'>
              {
                socialIcons.map((icon, index) => {
                  return (
                    <a href={icon.link} target="_blank"><img key={index} src={icon.icon} alt={icon.name} className="cursor-pointer"/></a>
                  )
                })
              }
            </div>
          </div>
      <p className='font-[400] text-center text-[14px] xl:w-10/12 mx-auto mt-10 md:mt-5'>Copyright @Talntworx Technologies Private Limited. 2024. All right reserved</p>

    </footer>
  )
}
