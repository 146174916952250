// import { data } from 'autoprefixer'
import React from 'react'
import { onBoardSectionFourContent } from '../../data/onBoard'
import { FeatureBox } from '../FeatureBox'

export const OnBoardSectionFour = () => {
  return (
      <section className='pt-6'>
          <h5 className='text-[25px] font-[700] pl-6 lg:pl-0 pb-10 lg:w-2/3 mx-auto lg:text-center'>{onBoardSectionFourContent.title.normal}<span className='text-[#0361FE]'>{onBoardSectionFourContent.title.coloured}</span>{onBoardSectionFourContent.title.normalContinuation}</h5>
          <div className='w-11/12 mx-auto'>
              {
              onBoardSectionFourContent.listData.map((data, index) => {
                  return <FeatureBox key={index} {...data} />
              })
          }
          </div>
    </section>
  )
}
