import React from 'react';
import arrow from "../images/arrow.svg";

export const FeatureBox = ({image,title,dimLine,listData}) => {
  return (
      <div className='bg-[#16072D] mb-10 h-auto xl:max-h-[520px] rounded-[15px] flex flex-col lg:flex-row lg:items-center'>
          <div className='lg:w-1/2 text-white p-5 pt-8 md:pl-10'>
              <h4 className='text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] font-[700] mb-6'>{title}</h4>
              {dimLine && <p className='text-[400] text-[#9f95ad] text-[18px] mb-6'>{dimLine}</p>}
              <div>
                  {
                      listData.map((data, index) => {
                          return (
                              <div className='flex pb-8 w-full' key={index}>
                                  <img src={arrow} alt="arrow" className=''/>
                                  <p className='ml-4 md:text-[17px]'>{data}</p>
                              </div>
                          )
                      })
                  }
              </div>
              
          </div>
          <img src={image} alt="featureImage" className='w-[33%] rounded-[8px] ml-[10%]'/>
    </div>
  )
}
