import React from 'react'
import { useEffect, useState } from 'react';
import { Meta,Title,HeadProvider } from 'react-head';
import { ModalStateProvider } from './ContactForm'
import { Footer } from './Footer'
import { Header } from './Header'

const SEO = () => {
  return (
    <>
      <Title>Talntworx Technologies Private Limited.</Title>
      <Meta name="Talntworx Technologies Private Limited." content="Talntworx Technologies Private Limited's TalntX is a people-centric, technology-driven software that reduces manual workload and eliminates the struggle to navigate repetitive tasks."/>
    </>
  )
}

export const Layout = ({ children }) => {
  const [isBrowser, setIsBrowser] = useState(false);
  
  useEffect(() => {
    setIsBrowser((typeof window !== undefined));
  }, []);

  if (isBrowser) {
    return (
      <HeadProvider>
        <SEO />
        <ModalStateProvider>
          <main className='font-[Manrope]'>
            {/* <SEO /> */}
              <Header />
                {children}
              <Footer />
        </main>
        </ModalStateProvider>
      </HeadProvider>
  )
  }
}