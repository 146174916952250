import React from 'react';
import gradientWithSpiralImage from "../images/gradientWithSpiralImage.svg";

export const FooterContactBox = ({children}) => {
  return (
      
      <div className='mb-3 h-auto relative rounded-xl'>
          <div className='lg:hidden mx-auto w-11/12 relative'>
          <div className='absolute  z-[-1] h-[100%] w-[100%] rounded-xl'></div>
          {/* <div className='absolute gradient z-[-1] h-[100%] w-[100%] rounded-xl'></div> */}
              {children}
            </div>
              <img src={gradientWithSpiralImage} className='hidden lg:block w-full h-[200px] md:h-[310px] xl:h-[350px] 2xl:h-[450px] object-cover' alt="gradientImage" />
              <div className='absolute hidden lg:block top-10 lg:top-14 2xl:top-[30%] left-12 lg:left-20 xl:left-[8%] 2xl:left-[7%]'>
                {children}
              </div>
          </div> 
          
  )
}
