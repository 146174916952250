import React, { useState,createContext,useContext } from 'react';
import whiteCloseBtn from "../images/whiteCloseBtn.svg";


const ModalContext = createContext();

export const ModalStateProvider = ({children}) => {
    const [showModal, setShowModal] = useState(false);
    const modalAction = () => setShowModal(!showModal);
    return (
        <ModalContext.Provider value={{showModal,modalAction}}>
            {showModal && <ContactForm />}
            {children}
        </ModalContext.Provider>
    )
}

export const useModal = () => useContext(ModalContext);


export const ContactForm = () => {
    const { modalAction: closeModal } = useModal();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formInput, setFormInput] = useState({
        name: '',
        mobile: "",
        message: "",
        email: ""
    });

    const handleChange = (e) => {
        let { name, value } = e.target;
        setFormInput({ ...formInput, [name]: value });
    }

    const inputDivsData = [
        {
            name: "name",
            type: "text",
            value: formInput.name,
            title:"Enter your name*"
        },
        {
            name: "email",
            type: "text",
            value:formInput.email,
            title:"Enter your email*"
        },
        {
            name: "mobile",
            type: "number",
            value:formInput.mobile,
            title:"Enter your phone number*"
        },
        {
            name: "message",
            type: "text",
            value:formInput.message,
            title:"Message* "
        },
    ]


    const onSubmitFunction = (e) => {
        e.preventDefault();
        // alert("Mail Sent");
        setFormInput({
            name: "",
            message: "",
            mobile: "",
            email: ""
        });
        setIsSubmitted(true);
    }

    const ThanksComponent = <div className='w-11/12 md:w-8/12 xl:w-5/12 mx-auto bg-white rounded-[25px] flex flex-col items-center py-10'>
        <h3>Thanks for contacting us !</h3>
        <button className='bg-[#0361FE] rounded-[48px] mt-8 text-white px-10 py-2' onClick={closeModal}>Close</button>
    </div>

  return (
      <div className='w-full h-[100%] flex items-center bg-black bg-opacity-[0.64] fixed overflow-auto py-10 z-[3] font-[Manrope]'>
          <div className='w-11/12 md:w-8/12 xl:w-5/12 mx-auto'>
              <div className='bg-[#0361FE] relative text-white pl-6 lg:pl-12 py-6 rounded-t-[25px]'>
                  <h3 className='font-[600] 2xl:text-center text-[15px] md:text-[20px] lg:text-[23px]'>To schedule a demo, Please write an email</h3>
                  <img src={whiteCloseBtn} alt="closeIcon" className='cursor-pointer absolute top-6 right-7 h-[15px] w-[15px]' onClick={closeModal}/>
              </div>
              <div className='bg-white rounded-b-[25px]'>
                  <h3 className='p-6 text-md lg:text-lg xl:text-xl text-center'><span className='text-[#0361FE] font-[800]'>accounts@talntworx.com</span></h3>
                  {/* <form className='flex flex-col w-11/12 pl-6 lg:pl-12 pb-8' onSubmit={onSubmitFunction}>
                  {
                      inputDivsData.map((data,index)=>{
                          return (
                              <input type={data.type} className={`mt-8 outline-none font-[400] text-[16px] pb-2 border-b border-[#BBBBBB] focus:border-[#0361FE] ${data.type==="number" && "number_input"}`} key={index} value={data.value} name={data.name} onChange={handleChange} placeholder={data.title} />
                        )
                      })
                  }
                  <button type='submit' className='self-end bg-[#0361FE] rounded-[48px] mt-8 text-white px-10 py-2'>Submit</button>
              </form> */}
              </div>

          </div> 
      </div>
          
  )
}
